import React, { useEffect, useLayoutEffect, lazy, Suspense, useMemo } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { parseCookies } from 'nookies';

import { useWallet } from 'contexts/wallet';
import { useBlurBackground } from 'helpers/hooks/useBlurBackground';
import { getCurrentChain } from 'helpers/wallet';
import { SOLANA_ROUTES } from './SolanaRoutes';
import { TON_ROUTES } from './TonRoutes';

// landing
const MainPage = lazy(() => import('pages/Common/Landing/Home'));
const Blog = lazy(() => import('pages/Common/Landing/Blog'));
const BlogPage = lazy(() => import('pages/Common/Landing/Blog/BlogPage'));
// lk
const Support = lazy(() => import('pages/Common/Support'));
const Faq = lazy(() => import('pages/Common/Faq'));
const Referral = lazy(() => import('pages/Common/Referral'));

export const RoutesFind = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { network } = useWallet();
  const { resetBlurBackground } = useBlurBackground();

  const routesWithoutLogin = ['', 'blog'];
  const currentWallet = sessionStorage.getItem('wallet');
  const chain = getCurrentChain(network);
  const isSolanaChain = chain === 'SOLANA';
  const isTonChain = chain === 'TON';

  const currentChainRoutes = useMemo(() => {
    if (isSolanaChain) return SOLANA_ROUTES;
    if (isTonChain) return TON_ROUTES;
    return SOLANA_ROUTES;
  }, [isTonChain, isSolanaChain]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (
      !routesWithoutLogin.includes(window.location.pathname.split('/')[1]) &&
      (!currentWallet || !parseCookies().acceptCookies)
    ) {
      navigate('/');
      return;
    }
    resetBlurBackground();
    if (currentWallet && routesWithoutLogin.includes(window.location.pathname.split('/')[1])) {
      navigate('/market');
    }
  }, [currentWallet, location.pathname]);

  return (
    <Routes>
      <Route
        path="/"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <MainPage />
          </Suspense>
        }
      />
      <Route
        path="/blog"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <Blog />
          </Suspense>
        }
      />
      <Route
        path="/blog/:blogKey"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <BlogPage />
          </Suspense>
        }
      />
      <Route
        path="/referral"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <Referral />
          </Suspense>
        }
      />
      <Route
        path="/support"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <Support />
          </Suspense>
        }
      />
      <Route
        path="/faq"
        exact
        element={
          <Suspense fallback={<h2>Loading...</h2>}>
            <Faq />
          </Suspense>
        }
      />

      {currentChainRoutes?.map((item) => (
        <Route
          key={item.path}
          path={item.path}
          exact
          element={<Suspense fallback={<h2>Loading...</h2>}>{item.component}</Suspense>}
        />
      ))}
    </Routes>
  );
};
