import React from 'react';

import { DashboardLayout } from 'layouts/DashboardLayout';
import MarketTableControllerTon from 'controllers/Ton/MarketTableController';
import { BreadCrumbs } from 'components/BreadCrumbs';

const Market = () => {
  return (
    <DashboardLayout>
      <BreadCrumbs title="Market" />
      <MarketTableControllerTon />
    </DashboardLayout>
  );
};

export default Market;
