import { useNavigate } from 'react-router-dom';

import { useWallet } from 'contexts/wallet';
import { useBlurBackground } from './useBlurBackground';
import { WALLET_TYPES } from 'contexts/constants';

export const useLogOut = () => {
  const { disconnectWallet, network, userAddress } = useWallet();
  const { resetBlurBackground } = useBlurBackground();
  const navigate = useNavigate();

  const logOut = () => {
    if (!userAddress) {
      sessionStorage.removeItem('wallet');
      resetBlurBackground();
      navigate('/');
      return;
    }
    resetBlurBackground();
    if (window.solana?.isConnected) {
      disconnectWallet(WALLET_TYPES.PHANTOM);
    } else if (window.coinbaseSolana?.isConnected) {
      disconnectWallet(WALLET_TYPES.COINBASE);
    } else if (window.solflare?.isConnected) {
      disconnectWallet(WALLET_TYPES.SOLFLARE);
    } else if (network === WALLET_TYPES.TONKEEPER) {
      disconnectWallet(WALLET_TYPES.TONKEEPER);
    }
  };

  return {
    logOut
  };
};
