import React, { lazy } from 'react';

const Market = lazy(() => import('pages/Solana/Market'));
const MyTokens = lazy(() => import('pages/Solana/MyTokens'));
// const CreateToken = lazy(() => import('pages/Solana/CreateToken'));

export const SOLANA_ROUTES = [
  { path: '/market', component: <Market /> },
  { path: '/myTokens', component: <MyTokens /> }
  // { path: '/createToken', component: <CreateToken /> }
];
