import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { HeaderDashboard } from 'components/Headers/Dashboard';
import LeftBar from 'components/LeftBar';
import { useRequest } from 'helpers/hooks/useRequest';
import { UserRepository } from 'connectors/repositories/user';
import { useWallet } from 'contexts/wallet';
import { setDailyMemesLeft } from 'store/userSlice';
import { getCurrentChain } from 'helpers/wallet';

export const DashboardLayout = ({ title = '', children }) => {
  const { userAddress, network } = useWallet();
  const chain = getCurrentChain(network);
  const dispatch = useDispatch();
  const { call, data } = useRequest(UserRepository.getUserDetails);
  const isSolanaChain = chain === 'SOLANA';

  useEffect(() => {
    if (userAddress && isSolanaChain) {
      call([{ account: userAddress }]);
    }
  }, [userAddress, isSolanaChain]);

  useEffect(() => {
    if (data?.error === 'Daily meme generation limit reached') {
      dispatch(setDailyMemesLeft(0));
    }
    if (data?.result) {
      dispatch(setDailyMemesLeft(data?.result?.dailyMemesLeft));
    }
  }, [data]);

  return (
    <div id="blurId" className="flex flex-col items-center min-h-screen">
      <HeaderDashboard />
      <div className="flex w-full h-full flex-1 justify-center">
        <LeftBar />
        <div className="max-w-[1112px] sm:max-w-full w-full p-[24px] bg-[#080808] rounded-[16px] overflow-auto flex flex-col flex-1 w-full p-6 sm:p-2">
          {children}
        </div>
      </div>
    </div>
  );
};
