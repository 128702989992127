import React from 'react';
import { Menu } from 'components/Menu';
import { UserInfo } from 'components/Headers/Dashboard/UserInfo';
import { SocialList } from 'components/SocialList';

const LeftBar = () => {
  return (
    <div className="z-50 sm:hidden sticky top-0 h-screen flex-shrink-0 w-[280px] flex flex-col justify-between items-center text-white pt-6 px-6 py-10 space-y-9">
      <div className="w-full flex flex-col items-center justify-start w-full">
        <img
          className="mt-2 mb-[40px] h-[38px] sm:h-[32px]"
          src="/logoWithText.svg"
          alt="header logo"
        />
        <Menu />
      </div>
      <div className="w-full flex flex-col items-center justify-end space-y-8">
        <UserInfo />
        <SocialList />
      </div>
    </div>
  );
};

export default LeftBar;
