import React, { useMemo } from 'react';

import { SOLANA_MENU_LIST, TON_MENU_LIST, DEFAULT_MENU_LIST } from './constants';
import { GroupMenu } from './Components/GroupMenu';
import { useWallet } from 'contexts/wallet';
import { getCurrentChain } from 'helpers/wallet';

export const Menu = () => {
  const { network } = useWallet();
  const chain = getCurrentChain(network);
  const isSolanaChain = chain === 'SOLANA';
  const isTonChain = chain === 'TON';

  const menuList = useMemo(() => {
    if (isSolanaChain) return SOLANA_MENU_LIST;
    if (isTonChain) return TON_MENU_LIST;
    return DEFAULT_MENU_LIST;
  }, [isTonChain, isSolanaChain]);

  return (
    <React.Fragment>
      <div className="flex flex-col w-full space-y-9">
        {Object.keys(menuList)?.map((itemGroup, index) => (
          <GroupMenu groupTitle={itemGroup} list={Object.values(menuList[itemGroup])} key={index} />
        ))}
      </div>
    </React.Fragment>
  );
};
