import React from 'react';
import { UserInfo } from './UserInfo';

export const HeaderDashboard = () => {
  return (
    <div className="hidden sm:flex z-10 header-bg sticky top-0 left-0 w-full h-[92px] sm:h-[72px] px-10 py-6 sm:px-[16px] sm:py-[14px] flex items-center justify-between">
      <img className="h-[38px] sm:h-[32px]" src="/logoWithText.svg" alt="header logo" />
      <UserInfo />
    </div>
  );
};
