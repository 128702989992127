import React from 'react';
import Typography from 'components/Typography';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography/constants';

export const BreadCrumbs = ({ title, children }) => {
  return (
    <div className="flex items-center justify-between mb-[39px] sm:mt-3 sm:mb-6">
      <div className="flex items-center justify-start sm:justify-center">
        <Typography className={'text-white'} text={title} variant={TYPOGRAPHY_VARIANTS.BODY_H2} />
      </div>
      {children}
    </div>
  );
};
